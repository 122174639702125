<template>
  <b-table :fields="table.fields" :items="tableItems" thead-class="table-header text-white text-center"
           tbody-tr-class="table-row" striped small fixed caption-top :class="tableClasses">
    <template #cell(template_id)="data">
      <b-icon-star-fill v-if="data.item.change" class="ml-n4" :variant="variantWarning"/>
      <span :class="{ 'ml-2': data.item.change }">{{ data.value }}</span>
    </template>
    <template #cell(courses)="data">
      <b-badge v-for="course in courseDescription(data.item.courses)" :key="course + data.item.id" class="ml-2 my-1 select"
               style="font-size: 0.8rem">
        {{ course }}
      </b-badge>
    </template>
    <template #cell(active_at)="data">
      <span>{{ data.value | date }}</span>
    </template>
    <template #custom-foot>
      <b-tr class="table-footer">
        <b-th v-for="(field, index) in table.fields" :key="index"/>
      </b-tr>
    </template>
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner variant="primary" class="align-middle"/>
      </div>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TemplateTable",
  props: ['tableItems'],
  data() {
    return {
      table: {
        fields: [
          {key: 'template_id', label: 'ID', thStyle: {width: '75px'}},
          {key: 'displayname', label: 'Name', thStyle: {width: '200px'}},
          {key: 'courses', label: 'Kurse', thStyle: {width: '800px'}},
          {key: 'active_at', label: 'Aktiv ab:', thStyle: {width: '100px'}, tdClass: 'text-center'}
        ]
      },
    }
  },
  methods: {
    courseDescription(courses) {
      return courses.split(';')
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    tableClasses() {
      if(this.userThemeLight) {
        return []
      }
      return ['text-white']
    },
    variantWarning() {
      if(this.userColorAccessibility === 'red-green') { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/styles/Tables';
</style>
