<template>
  <b-table :fields="computedFields" :items="tableItems" :busy="loading" :class="tableClasses" striped small fixed
           caption-top thead-class="table-header text-white text-center" tbody-tr-class="table-row" @row-clicked="edit">
    <template #cell(note_other)="data">
      <div style="white-space: pre-line">
        {{ data.item.note_other }}
      </div>
    </template>
    <template #cell(note)="data">
      <div style="white-space: pre-line">
        {{ data.item.note }}
      </div>
    </template>
    <template #cell(changelog)="data">
      <div style="white-space: pre-line">
        {{ data.item.changelog }}
      </div>
      <div class="mr-2 text-secondary">
        geändert von {{ data.item.user.forename }} {{ data.item.user.surname }} am {{ data.item.updated_at | date }}
      </div>
    </template>
    <template #custom-foot>
      <b-tr class="table-footer">
        <b-th v-for="n in footerLength" :key="n"/>
      </b-tr>
    </template>
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner variant="primary" class="align-middle"/>
      </div>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ConsultingChecklistTable",
  props: ['tableItems', 'loading', 'consulting'],
  data() {
    return {
      table: {
        fields: [
          {key: 'course', label: 'Kurs', thStyle: {minWidth: '100px'}, thClass: 'th-sticky-consulting', tdClass: ['py-auto', 'align-middle']},
          {key: 'column_one', label: 'SZG', formatter: 'columnFormatter', thStyle: {width: '80px'}, thClass: 'th-sticky-consulting', tdClass: (value) => {
              if(value.name === 'yes') {
                return 'text-center td-success text-bold align-middle'
              }
              if(value.name === 'no') {
                return 'text-center td-danger text-bold align-middle'
              }
              if(value.name === 'maybe') {
                return 'text-center td-warning text-bold align-middle'
              }
              return 'text-center'
            }},
          {key: 'column_two', label: 'SZNG', formatter: 'columnFormatter', thStyle: {width: '80px'}, thClass: 'th-sticky-consulting', tdClass: (value) => {
              if(value.name === 'yes') {
                return 'text-center td-success text-bold align-middle'
              }
              if(value.name === 'no') {
                return 'text-center td-danger text-bold align-middle'
              }
              if(value.name === 'maybe') {
                return 'text-center td-warning text-bold align-middle'
              }
              return 'text-center'
            }},
          {key: 'column_three', label: 'FIRMEN', formatter: 'columnFormatter', thStyle: {width: '80px'}, thClass: 'th-sticky-consulting', tdClass: (value) => {
              if(value.name === 'yes') {
                return 'text-center td-success text-bold align-middle'
              }
              if(value.name === 'no') {
                return 'text-center td-danger text-bold align-middle'
              }
              if(value.name === 'maybe') {
                return 'text-center td-warning text-bold align-middle'
              }
              return 'text-center'
            }},
          {key: 'column_four', label: 'SGB II', formatter: 'columnFormatter', thStyle: {width: '80px'}, thClass: 'th-sticky-consulting', tdClass: (value) => {
              if(value.name === 'yes') {
                return 'text-center td-success text-bold align-middle'
              }
              if(value.name === 'no') {
                return 'text-center td-danger text-bold align-middle'
              }
              if(value.name === 'maybe') {
                return 'text-center td-warning text-bold align-middle'
              }
              return 'text-center'
            }},
          {key: 'column_five', label: 'DRV / BGS SGB IX / SGB VII', formatter: 'columnFormatter', thStyle: {width: '100px'}, thClass: 'th-sticky-consulting', tdClass: (value) => {
              if(value.name === 'yes') {
                return 'text-center td-success text-bold align-middle'
              }
              if(value.name === 'no') {
                return 'text-center td-danger text-bold align-middle'
              }
              if(value.name === 'maybe') {
                return 'text-center td-warning text-bold align-middle'
              }
              return 'text-center'
            }},
          {key: 'note_other', label: 'Sonstige mögliche Förderung', thStyle: {width: '400px'}, thClass: 'th-sticky-consulting'},
          {key: 'note', label: 'Notiz', thStyle: {width: '350px'}, thClass: 'th-sticky-consulting'},
          {key: 'changelog', label: 'Änderungsverlauf', thStyle: {width: '400px'}, thClass: 'th-sticky-consulting', requiresConsulting: true}
        ]
      }
    }
  },
  methods: {
    edit(data) {
      if(this.consulting) {
        this.$emit('edit-entry', data)
      }
    },
    badgeStyle(data) {
      return {
        'color': data.color_font,
        'background-color':data.color_background
      }
    },
    columnFormatter(value) {
      return value.displayname
    }
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    tableClasses() {
      if(this.userThemeLight) {
        return []
      }
      return ['text-white']
    },
    computedFields() {
      if(!this.consulting)
        return this.table.fields.filter(field => !field.requiresConsulting);
      else
        return this.table.fields;
    },
    footerLength() {
      if(this.consulting) {
        return this.table.fields.length
      }
      return this.table.fields.length - 1
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/Tables';
</style>
