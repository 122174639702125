<template>
  <b-table :fields="computedFields" :items="tableItems" :busy="loading" :class="tableClasses" striped small fixed
           caption-top thead-class="table-header text-white text-center" tbody-tr-class="table-row" @row-clicked="edit">
    <template #cell(technical_information)="data">
      <VueMarkdownRender :source="data.item.technical_information" :class="renderClasses"/>
    </template>
    <template #cell(contact_information)="data">
      <VueMarkdownRender :source="data.item.contact_information" :class="renderClasses"/>
    </template>
    <template #cell(repeat_exam_information)="data">
      <VueMarkdownRender :source="data.item.repeat_exam_information" :class="renderClasses"/>
    </template>
    <template #cell(note)="data">
      <VueMarkdownRender :source="data.item.note" :class="renderClasses"/>
    </template>
    <template #custom-foot>
      <b-tr class="table-footer">
        <b-th v-for="n in footerLength" :key="n"/>
      </b-tr>
    </template>
    <template #table-busy>
      <div class="text-center my-2">
        <b-spinner variant="primary" class="align-middle"/>
      </div>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex";
import VueMarkdownRender from "vue-markdown-render";

export default {
  name: "ExamChecklistTable",
  props: ['tableItems', 'fgExam', 'loading'],
  components: {
    VueMarkdownRender
  },
  data() {
    return {
      table: {
        fields: [
          {key: 'course', label: 'Anbieter / Kurs', thStyle: {width: '250px'}, thClass: 'th-sticky-exam', tdClass: ['py-auto', 'align-middle']},
          {key: 'exam_date', label: 'Prüfungstermin', thStyle: {width: '200px'}, thClass: 'th-sticky-exam', tdClass: ['py-auto', 'align-middle']},
          {key: 'technical_information', label: 'Technische Hinweise', thStyle: {width: '450px'}, thClass: 'th-sticky-exam'},
          {key: 'contact_information', label: 'Links / Ansprechpartner', thStyle: {width: '350px'}, thClass: 'th-sticky-exam'},
          {key: 'repeat_exam_information', label: 'Wiederholungsprüfung', thStyle: {width: '350px'}, thClass: 'th-sticky-exam'},
          {key: 'note', label: 'Notizen', thStyle: {width: '250px'}, thClass: 'th-sticky-exam', requiresFgExam: true}
        ]
      }
    }
  },
  methods: {
    edit(data) {
      if(this.fgExam) {
        this.$emit('edit-entry', data)
      }
    },
  },
  computed: {
    ...mapGetters(['userThemeLight']),
    renderClasses() {
      if(this.userThemeLight) { return ['text-dark', 'link-overflow'] }
      return ['text-white', 'link-overflow']
    },
    tableClasses() {
      if(this.userThemeLight) {
        return []
      }
      return ['text-white']
    },
    computedFields() {
      if(!this.fgExam)
        return this.table.fields.filter(field => !field.requiresFgExam);
      else
        return this.table.fields;
    },
    footerLength() {
      if(this.fgExam) {
        return this.table.fields.length
      }
      return this.table.fields.length - 1
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/Tables';

.link-overflow {
  overflow-wrap: break-word;
  white-space: pre-line;
}
</style>
